import styled from 'styled-components';
import { Col, Divider, Row } from 'antd';

export const Tax = styled.div`
  font-size: 1rem;
  color: var(--tertiary-color);
  float: right;
  @media (max-width: 768px) {
    font-size: 0.8rem;
    line-height: 0.9rem;
  }
`;

export const Wrapper = styled.div`
  margin-left: 20px;
  padding: 8px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #686868;
  width: fit-content;

  @media (max-width: 768px) {
    padding: 7px;
    width: 150px;
  }
  @media (max-width: 500px) {
    margin-left: 0;
    padding: 0;
    width: 100%;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  > span {
    display: flex;
    align-items: center;
  }

  @media (max-width: 768px) {
    margin: 0 auto;
    > span {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
  }
  @media (max-width: 500px) {
    margin: 0 auto;
    > span {
      display: none;
    }
  }
`;

export const TitleText = styled.span`
  font-weight: 500;
  line-height: 24px;

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const ValueTextWrapper = styled.div`
  margin-left: 10px;
  line-height: 2.5rem;
  > span {
    font-weight: 700;
    color: var(--button-color);
  }
  .anticon {
    font-weight: 300;
  }
  @media (max-width: 768px) {
    line-height: 2.2rem;
    display: flex;
    align-items: center;
  }
`;

export const CoinText = styled.span`
  font-weight: 300;
`;

export const ValueText = styled.span`
  font-weight: 500;
  color: var(--secondary-color);
`;

export const Title = styled.h3`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  height: 18px;
  color: #3b403f;
`;
export const Line = styled(Divider)`
  width: 490px !important;
  min-width: 50% !important;
  margin: 15px 0px 15px 0px;
`;

export const InfoDiv = styled.div`
  width: 100%;

  /* Body/Body 03/Body 03 Reg */
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */

  display: flex;

  /* Neutrals/N4 */

  color: #5e6665;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;

  ${Line} {
    width: 90%;
    margin: 10px 0px 10px 0px;
  }
`;
export const InfoRow = styled(Row)`
  /* Frame 2608640 */

  /* Auto layout */

  justify-content: space-between;
  align-items: center;
  align-content: center;

  width: 100%;
  height: 35px;
`;
export const Icon = styled.img`
  /* help */
  width: 13px;
  height: 13px;
  margin-top: 3px;
  margin-right: 4px;

  /* Neutrals/N3 */

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;
export const InfoText = styled.p`
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  height: 40px;
  color: #5e6665;
`;

export const InfoAnswer = styled.div`
  width: 45%;
  height: 12px;
  margin-top: -5px;
  /* Body/Body 02/Body 02 Med */

  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  ${'' /* line-height: 22px; */}
  /* identical to box height, or 138% */

  /* Neutrals/N5 */

  color: #3B403F;
  /* Inside auto layout */
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  gap: 16px;
  padding-top: 10px;
  width: 100%;
  min-height: 60px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  ${InfoAnswer} {
    display: flex;
    justify-content: flex-end;
  }
`;

export const Price = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
`;

export const InfoQuery = styled.div`
  max-width: 150px;
  width: 100%;
  height: 12px;

  /* Body/Body 03/Body 03 Bold */

  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;

  /* Neutrals/N3 */

  color: #8d9997;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const ItemPopup = styled(Col)`
  /* Summary modal- Move type and items, moves */

  box-sizing: border-box;

  align-content: center;
  align-items: center;
  /* Auto layout */
  padding: 20px 30px;

  max-width: 450px;
  width: 100%;
  min-height: 200px;

  /* N5 */

  background: #ffffff;
  /* N8 */

  ${Line} {
    width: 100% !important;
  }

  ${InfoContainer} {
    margin-top: -30px !important;
    width: 100%;
  }
`;

export const InfoTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer !important;

  padding: 0px;
  gap: 8px;

  width: 100%;
  height: 24px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  &:hover {
    opacity: 0.7;
  }
`;

export const InfoLabel = styled.label`
  max-width: 60px;
  width: 100%;
  height: 22px;

  /* Body/Body 02/Body 02 Bold */

  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 138% */

  display: flex;
  align-items: center;

  /* Neutrals/N4 */

  color: #3b403f;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
`;

export const ItemDisplay = styled.div`
  /* Auto layout */
  align-items: center;
  text-align: center;
  align-content: center;
  padding: 12px 16px;
  gap: 8px;

  width: 45%;
  height: 30px;

  /* N6 */

  background: #f8f8f8;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  ${InfoAnswer} {
    width: 100%;
  }
`;
