const volumePerMates = [
  { volume: 650, mates: 2 },
  { volume: 1050, mates: 3 },
];

/**
 * Calculate the required number of mates for the move.
 * *PS. Big moves requires 4 mates*
 * @param {number} cuft volume of items to move in cubic feet
 * @returns {number} number of mates required for the move
 */
export const calculateRequiredMates = (cuft = 0) => {
  const matchingVolume = volumePerMates.find((vp) => cuft <= vp.volume);
  if (matchingVolume) return matchingVolume.mates;
  return 4;
};
