/**
 * Build string representation of address from its components
 * @param {Object} addressComponents
 * @returns {String} string representation of address
 */
export const buildAddressString = (addressComponents) => {
  const unit = addressComponents.unit ? addressComponents.unit + ' ' : '';
  return `${unit || addressComponents.aptUnit || ''} ${
    addressComponents.street || addressComponents.streetName
  }, ${addressComponents.city}, ${addressComponents.province} ${
    addressComponents.postalCode
  }, ${addressComponents.country}`;
};
