import axios from 'axios';

export default class PartnerInvoiceService {
  /**
   * Gets all invoices based on partner
   * @param {string} partnerID partnerID related to the invoice
   * @param {int} page the page of invoices
   * @param {int} perPage the number of invoices per page
   * @param {string} accessToken
   */
  getAllInvoices(partnerID, page, perPage, accessToken) {
    return axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/b2b/partner?page=${page}&per_page=${perPage}&partnerID=${partnerID}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }

  /**
   * Gets all invoices based on partner
   * @param {int} page the page of invoices
   * @param {int} perPage the number of invoices per page
   * @param {list} filterDict the filters in place
   * @param {string} accessToken
   */
  getFilteredInvoices(partnerID, filterDict, page, perPage, accessToken) {
    let query = '';
    for (const [key, value] of Object.entries(filterDict)) {
      if (typeof value === 'string' || value instanceof String) {
        query += `&${key}=${value}`;
      } else {
        query += `&${key}=${JSON.stringify(value)}`;
      }
    }
    return axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/b2b/partner/${partnerID}/invoices?page=${page}&per_page=${perPage}${query}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }
}
