import {
  CLEAR_CITY,
  CREATE_CITY,
  CREATE_CITY_SUCCESS,
  CREATE_CITY_ERROR,
  GET_CITY,
  GET_CITY_SUCCESS,
  GET_CITY_ERROR,
  GET_CITY_BY_ID,
  GET_CITY_BY_ID_SUCCESS,
  GET_CITY_BY_ID_ERROR,
  UPDATE_CITY,
  UPDATE_CITY_SUCCESS,
  UPDATE_CITY_ERROR,
  DELETE_CITY,
  DELETE_CITY_SUCCESS,
  DELETE_CITY_ERROR,
} from '../constants/city';
import CityService from '../../services/CityService';

const cityService = new CityService();

/**
 * Reset city viewedPartner
 */
export const resetCity = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_CITY });
  };
};

/**
 * Gets the list of cities
 * @param {string} accessToken
 */
export const getCities = (accessToken, currentPage, page) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_CITY,
      });
      const resp = await cityService.getCity(accessToken, currentPage, page);
      dispatch({
        type: GET_CITY_SUCCESS,
        cities: resp.data,
        count: resp.count,
      });
    } catch (error) {
      dispatch({
        type: GET_CITY_ERROR,
        payload: error,
      });
    }
  };
};

/**
 * Gets a city by ID
 * @param {string} cityId
 * @param {string} accessToken
 */
export const getCityById = (cityId, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_CITY_BY_ID,
      });
      const resp = await cityService.getCityById(cityId, accessToken);
      dispatch({
        type: GET_CITY_BY_ID_SUCCESS,
        data: resp,
      });
    } catch (error) {
      dispatch({
        type: GET_CITY_BY_ID_ERROR,
        payload: error,
      });
    }
  };
};
/**
 * Creates a city
 * @param {obj} cityData The data to create city
 *                       name {string} Name of the City
 *                       coordinates {array[float, float]} list of coordinate in [Long, Lat]
 *                       countryCode {string} The Country Standard Code (ref: ISO 3166 / ALPHA-2)
 *                       stateCode {string} The State/Province Standard Code (ref: ISO 3166 / ALPHA-2)
 *                       maxDistance {float} Maximum driving distance in KM
 * @param {string} accessToken Access token obtained from auth0 to check if user is authorized to access endpoint
 * @returns
 */
export const createCity = (cityData, accessToken) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_CITY,
      loading: true,
    });
    try {
      const resp = await cityService.createCity(cityData, accessToken);
      dispatch({
        type: CREATE_CITY_SUCCESS,
        payload: resp.data,
      });
    } catch (err) {
      dispatch({
        type: CREATE_CITY_ERROR,
        error: err,
      });
      throw new Error(`Error ${JSON.stringify(err.toJSON().message)}`);
    }
  };
};
/**
 * Update a city
 * @param {obj} cityData The data to create city
 *                      name {string} Name of the City
 *                      coordinates {array[float, float]} list of coordinate in [Long, Lat]
 *                      countryCode {string} The Country Standard Code (ref: ISO 3166 / ALPHA-2)
 *                      stateCode {string} The State/Province Standard Code (ref: ISO 3166 / ALPHA-2)
 *                      maxDistance {float} Maximum driving distance in KM
 * @param {string} accessToken Access token obtained from auth0 to check if user is authorized to access endpoint
 * @returns
 */
export const updateCity = (cityId, cityData, accessToken) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_CITY,
      loading: true,
    });
    try {
      const resp = await cityService.updateCity(cityId, cityData, accessToken);
      dispatch({
        type: UPDATE_CITY_SUCCESS,
        payload: resp.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_CITY_ERROR,
        error: err,
      });
      throw new Error(`Error ${JSON.stringify(err.toJSON().message)}`);
    }
  };
};

/**
 * Update a city
 * @param {obj} cityData The data to create city
 *                      name {string} Name of the City
 *                      coordinates {array[float, float]} list of coordinate in [Long, Lat]
 *                      countryCode {string} The Country Standard Code (ref: ISO 3166 / ALPHA-2)
 *                      stateCode {string} The State/Province Standard Code (ref: ISO 3166 / ALPHA-2)
 *                      maxDistance {float} Maximum driving distance in KM
 * @param {string} accessToken Access token obtained from auth0 to check if user is authorized to access endpoint
 * @returns
 */
export const deleteCity = (cityId, accessToken) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_CITY,
      loading: true,
    });
    try {
      const resp = await cityService.deleteCity(cityId, accessToken);
      dispatch({
        type: DELETE_CITY_SUCCESS,
        payload: resp.data,
      });
    } catch (err) {
      dispatch({
        type: DELETE_CITY_ERROR,
        error: err,
      });
      throw new Error(`Error ${JSON.stringify(err.toJSON().message)}`);
    }
  };
};
