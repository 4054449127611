import axios from 'axios'
import convertMapsToAddress from "./convertMapsToAddress";

const GOOGLE_MAPS_URL = 'https://maps.googleapis.com/maps/api/geocode/json?'
const GOOGLE_MAPS_TIMEZONE_URL = 'https://maps.googleapis.com/maps/api/timezone/json?'
const GOOGLE_MAPS_KEY = `${process.env.REACT_APP_GOOGLE_MAPS_API}`

export default async function getAddressDetail(address = '') {
  try {
    const { data } = await axios.get(GOOGLE_MAPS_URL, {
      params: {
        key: GOOGLE_MAPS_KEY,
        address
      }
    })
    return data.results[0]
  } catch (error) {
    console.error(`Error to get address coords ${error}`)
  }
}

export async function getAddressAndCoords(address = '') {
  const addressResult = await getAddressDetail(address)
  if (addressResult && addressResult.geometry) {
    return {
      location: convertMapsToAddress(addressResult),
      coordinates: addressResult.geometry.location,
    }
  }
  return {}
}

export async function getTimeZoneByCoords(lat = '45.54', lng = '-73.65') {
  try {
    const { data } = await axios.get(GOOGLE_MAPS_TIMEZONE_URL, {
      params: {
        key: GOOGLE_MAPS_KEY,
        location: `${lat},${lng}`,
        timestamp: `${parseInt(new Date().getTime() / 1000)}`
      }
    })
    return data.timeZoneId || ''
  } catch (error) {
    console.error(error)
  }
}