/**
 * province object that holds the provinces we currently operate in
 * hardcoded for now per request.
 * TODO: eventually will need to refactor it so it will be controlled within our backend & dashboard.
 */
export const provinceMap = {
  'British Columbia': 'BC',
  'Quebec': 'QC',
  'Québec': 'QC',
  'Manitoba': 'MB',
  'Alberta': 'AB',
  'Ontario': 'ON',
};

export const PROVINCES_OF_WORK = Object.values(provinceMap);
