import React from 'react';
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Col, Row } from 'antd';
import * as S from './style';
import { DEFAULT_BOX } from 'sharedlibs/lib/constants';

export default function EstimatePopover({ totalItems }) {
  const history = useHistory();
  const intl = useIntl();
  const { bookingId = '' } = useParams();
  const { estimate } = useSelector(({ bookingReducer, tempBookingReducer }) =>
    !bookingId ? bookingReducer : tempBookingReducer
  );

  const bookingData = useSelector(({ bookingReducer, tempBookingReducer }) =>
    !bookingId ? bookingReducer : tempBookingReducer
  );

  const renderAddress = (address) => {
    let addressString = `${address.unit} ${address.street}`;
    return addressString;
  };

  const { distance } = useSelector(({ mapboxReducer, tempMapboxReducer }) =>
    !bookingId ? mapboxReducer : tempMapboxReducer
  );

  const timezone = useSelector(({ geoReducer }) => geoReducer.timezone);

  const isMove = bookingData.bookingType === 'moves';

  const renderLocations = () => {
    if (
      bookingData.pickupCoords !== null &&
      bookingData.dropoffCoords !== null
    ) {
      return (
        <Col>
          <S.Line />
          <S.InfoTitle onClick={() => history.push(`/locations/${bookingId}`)}>
            <S.InfoLabel>
              {intl.formatMessage({ id: 'model.locations' })}
            </S.InfoLabel>
            <S.Icon src="/icons/edit.svg" />
          </S.InfoTitle>
          <S.InfoRow>
            <S.InfoAnswer>{renderAddress(bookingData.pickup)}</S.InfoAnswer>
            <S.Icon src="/icons/arrow_circle_right.svg" />
            <S.InfoAnswer style={{ textAlign: 'right' }}>
              {renderAddress(bookingData.dropoff)}
            </S.InfoAnswer>
          </S.InfoRow>
        </Col>
      );
    } else {
      return <></>;
    }
  };

  const renderMoveDate = () => {
    if (bookingData.date !== null) {
      return (
        <Col>
          <S.Line />
          <S.InfoTitle onClick={() => history.push(`/schedule/${bookingId}`)}>
            <S.InfoLabel>
              {intl.formatMessage({ id: 'model.timeDate' })}
            </S.InfoLabel>
            <S.Icon src="/icons/edit.svg" />
          </S.InfoTitle>
          <S.InfoRow>
            <S.InfoAnswer>{renderDateTime()}</S.InfoAnswer>
          </S.InfoRow>
        </Col>
      );
    } else {
      return <></>;
    }
  };

  const renderMoveType = () => {
    if (bookingData.bookingType !== null) {
      return (
        <Col>
          <S.Line />
          <S.InfoTitle onClick={() => history.push(`/move-type/${bookingId}`)}>
            <S.InfoLabel>
              {intl.formatMessage({ id: 'model.moveType' })}
            </S.InfoLabel>
            <S.Icon src="/icons/edit.svg" />
          </S.InfoTitle>
          <S.InfoRow>
            <S.InfoAnswer style={{ width: 'auto' }}>
              {isMove
                ? intl.formatMessage({ id: 'model.moves' })
                : intl.formatMessage({ id: 'model.itemDelivery' })}
            </S.InfoAnswer>
            {renderItems()}
          </S.InfoRow>
        </Col>
      );
    }
    return null;
  };

  const renderDateTime = () => {
    let newTime = new Date(bookingData.time).toLocaleTimeString('en-US', {
      timeZone: timezone,
    });
    let newDate = new Date(bookingData.date).toLocaleDateString('en-US', {
      timeZone: timezone,
    });

    return `${newDate}   -   ${newTime}`;
  };

  const renderItems = () => {
    let boxesNum = 0;
    let roomsNum = 0;
    const itemsNum = totalItems
      ? totalItems
      : bookingData.selectedFurnitures.reduce(
          (prev, curr) => prev + curr.quantity,
          0
        );
    if (isMove) {
      boxesNum = bookingData.selectedFurnitures.reduce((prev, curr) => {
        if (curr.id === DEFAULT_BOX) {
          return prev + curr.quantity;
        }
        return prev;
      }, 0);
    }
    if (bookingData?.rooms) {
      roomsNum = bookingData?.rooms?.reduce(
        (prev, curr) => prev + curr.quantity,
        0
      );
    }
    return (
      <>
        {isMove ? (
          <Row style={{ width: '60%', justifyContent: 'flex-end', gap: 10 }}>
            <S.ItemDisplay>
              <S.InfoAnswer>
                {`${roomsNum} ${intl.formatMessage({ id: 'model.rooms' })}`}
              </S.InfoAnswer>
            </S.ItemDisplay>

            <S.ItemDisplay>
              <S.InfoAnswer>
                {`${boxesNum} ${intl.formatMessage({ id: 'model.bagsBoxes' })}`}
              </S.InfoAnswer>
            </S.ItemDisplay>
          </Row>
        ) : (
          <Row style={{ width: '50%', justifyContent: 'flex-end' }}>
            <S.ItemDisplay>
              <S.InfoAnswer>
                {`${itemsNum} ${intl.formatMessage({ id: 'model.items' })}`}
              </S.InfoAnswer>
            </S.ItemDisplay>
          </Row>
        )}
      </>
    );
  };

  const renderDistance = (distance) => {
    let km = distance / 1000;
    return `${km.toFixed(2)}`;
  };

  const calculateCubicFeet = (selectedItems) => {
    return selectedItems.reduce(
      (acc, furniture) =>
        acc + Number(furniture.quantity) * Number(furniture.cubic),
      0
    );
  };

  const longDistance = estimate.longDistancePrice;
  const packing = estimate.packingPrice;
  const lastMinute = estimate.lastMinutePriceRef;
  const { longDistanceNightPrice } = estimate;
  const lastMinutePrice = () => {
    return estimate.amount - lastMinute?.estimatedPrice;
  };

  const renderPrice = () => {
    if (distance) {
      return (
        <Col style={{ width: '100%', justifyContent: 'spa' }}>
          <S.InfoContainer>
            <S.InfoQuery>
              {intl.formatMessage({ id: 'model.distance' })}
            </S.InfoQuery>
            <S.InfoAnswer>{renderDistance(distance)} km</S.InfoAnswer>
          </S.InfoContainer>
          <S.InfoContainer>
            <S.InfoQuery>
              {intl.formatMessage({ id: 'model.moveSize' })}
            </S.InfoQuery>
            <S.InfoAnswer>
              {intl.formatMessage(
                { id: 'model.approxcf' },
                { cf: calculateCubicFeet(bookingData.selectedFurnitures) }
              )}
            </S.InfoAnswer>
          </S.InfoContainer>
          <br />
          <S.InfoTitle style={{ width: '100%' }}>
            <S.HeaderTitle>
              <S.Icon
                src="/icons/request_quote.svg"
                style={{ width: 24, height: 24 }}
              />
              <S.InfoLabel>
                {intl.formatMessage({ id: 'model.priceInfo' })}
              </S.InfoLabel>
            </S.HeaderTitle>
          </S.InfoTitle>
          <br />
          {!!longDistance || !!packing || !!lastMinute ? (
            <S.InfoContainer>
              <S.InfoQuery>
                {intl.formatMessage({ id: 'model.basePrice' })}
              </S.InfoQuery>
              <S.InfoAnswer>
                {`$${(lastMinute?.estimatedPrice
                  ? lastMinute?.estimatedPrice -
                    lastMinute?.longDistancePrice -
                    longDistanceNightPrice -
                    lastMinute?.packingPrice
                  : estimate.amount -
                    longDistance -
                    packing -
                    longDistanceNightPrice
                ).toFixed(2)}`}
              </S.InfoAnswer>
            </S.InfoContainer>
          ) : null}
          {!!longDistance && (
            <>
              <S.InfoContainer>
                <S.InfoQuery>
                  {intl.formatMessage({ id: 'model.longDistance' })}
                </S.InfoQuery>
                <S.InfoAnswer>
                  {`$${
                    (
                      (lastMinute?.longDistancePrice || longDistance) +
                      (longDistanceNightPrice || 0)
                    )?.toFixed(2) || 0
                  }`}
                </S.InfoAnswer>
              </S.InfoContainer>
            </>
          )}
          {!!packing && (
            <>
              <S.InfoContainer>
                <S.InfoQuery>
                  {intl.formatMessage({ id: 'model.packingPrice' })}
                </S.InfoQuery>
                <S.InfoAnswer>{`$${
                  (lastMinute?.packingPrice || packing).toFixed(2) || 0
                }`}</S.InfoAnswer>
              </S.InfoContainer>
            </>
          )}
          {!!lastMinute && (
            <>
              <S.InfoContainer>
                <S.InfoQuery>
                  {intl.formatMessage({ id: 'model.lastMinutePrice' })}
                </S.InfoQuery>
                <S.InfoAnswer>
                  {`$${lastMinutePrice().toFixed(2)}`}
                </S.InfoAnswer>
              </S.InfoContainer>
            </>
          )}
          <S.InfoContainer>
            <S.InfoQuery>
              {intl.formatMessage({ id: 'model.subtotal' })}
            </S.InfoQuery>
            <Col>
              <S.Price style={{ display: 'flex', gap: '5px' }}>
                {`$${estimate.amount.toFixed(2)}`}
                <S.Tax>{intl.formatMessage({ id: 'model.plusTax' })}</S.Tax>
              </S.Price>
            </Col>
          </S.InfoContainer>
        </Col>
      );
    } else {
      return (
        <S.InfoTitle style={{ width: '100%' }}>
          <S.HeaderTitle>
            <S.Icon
              src="/icons/request_quote.svg"
              style={{ width: 24, height: 24 }}
            />
            <S.InfoLabel>
              {intl.formatMessage({ id: 'model.priceInfo' })}
            </S.InfoLabel>
          </S.HeaderTitle>
        </S.InfoTitle>
      );
    }
  };

  return (
    <S.ItemPopup>
      <S.InfoDiv>
        <S.Icon src="/icons/help.svg" />
        <S.InfoText>
          {intl.formatMessage({ id: 'description.tooltip.estimatedPrice' })}
        </S.InfoText>
      </S.InfoDiv>
      <br />
      <S.InfoDiv>
        <S.Title>{intl.formatMessage({ id: 'model.moveDetails' })}</S.Title>
      </S.InfoDiv>
      <Col>
        {renderLocations()}
        {renderMoveType()}
        {renderMoveDate()}
      </Col>
      <S.Line />
      <S.InfoDiv>{renderPrice()}</S.InfoDiv>
    </S.ItemPopup>
  );
}
