import axios from 'axios';

export default class CityService {
  /**
   * Gets the list of cities
   * @param {string} accessToken
   */
  getCity(accessToken, page = 0, limit = 20, query = '') {
    return axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/cities?page=${page}&per_page=${limit}` + (query ? `&${query}` : ''), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((resp) => resp.data);
  }

  /**
   * Gets the city by id
   * @param {string} cityId
   * @param {string} accessToken
   */
  getCityById(cityId, accessToken) {
    return axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/cities/${cityId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((resp) => resp.data);
  }

  /**
   * Creates a city
   * @param {obj} cityData The data to create city
   *                      name {string} Name of the City
   *                      coordinates {array[float, float]} list of coordinate in [Long, Lat]
   *                      countryCode {string} The Country Standard Code (ref: ISO 3166 / ALPHA-2)
   *                      stateCode {string} The State/Province Standard Code (ref: ISO 3166 / ALPHA-2)
   *                      maxDistance {float} Maximum driving distance in KM
   * @param {string} accessToken The current user access token to ping the backend
   */
  createCity(cityData, accessToken) {
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/cities`, cityData, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }
  /**
   * Update a city
   * @param {string} cityId cityId {string} the city id
   * @param {obj} cityData The data to create city
   *                      name {string} Name of the City
   *                      coordinates {array[float, float]} list of coordinate in [Long, Lat]
   *                      countryCode {string} The Country Standard Code (ref: ISO 3166 / ALPHA-2)
   *                      stateCode {string} The State/Province Standard Code (ref: ISO 3166 / ALPHA-2)
   *                      maxDistance {float} Maximum driving distance in KM
   * @param {string} accessToken The current user access token to ping the backend
   */
  updateCity(cityId, cityData, accessToken) {
    return axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/cities/${cityId}`,
      cityData,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }
  /**
   * Delete a city
   * @param {obj} cityData The data to delete city
   *                      cityId {string} the city id
   * @param {string} accessToken The current user access token to ping the backend
   */
  deleteCity(cityId, accessToken) {
    return axios.delete(`${process.env.REACT_APP_BACKEND_URL}/cities/${cityId}`, {
      headers: { Authorization: `Bearer ${accessToken}` },
    });
  }
}
