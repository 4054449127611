export const progressSelector = (state) => {
  // progress options: ['current', 'incomplete', 'complete']
  const progress = ['current', 'incomplete', 'incomplete'];

  const step1 = locationProgress(state);
  const step2 = moveTypeProgress(state);
  const step3 = scheduleProgress(state);

  progress[0] = checkState(true, step1);
  progress[1] = checkState(step1, step2);
  progress[2] = checkState(step2, step3);

  return progress;
};

const checkState = (previousStep, currentStep) => {
  if (previousStep && !currentStep) {
    return 'current';
  } else if (previousStep && currentStep) {
    return 'complete';
  }
  return 'incomplete';
};

/**
 * determine the progress of the location progress
 * @param {object} state current redux store state
 */
export const locationProgress = (state) => {
  // check that required fields for location are filled out
  const { pickup, dropoff, pickupCoords, dropoffCoords } = state;
  return (
    pickup !== null &&
    dropoff !== null &&
    pickup !== '' &&
    dropoff !== '' &&
    pickupCoords !== null &&
    dropoffCoords !== null
  );
};

/**
 * determine progress of of the move type
 * @param {*} state
 */
export const moveTypeProgress = (state) => {
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { selectedFurnitures, estimatedDuration } = state;
  return selectedFurnitures.length > 0;
};

/**
 * determine progress of schedule progress
 * @param {*} state
 */
export const scheduleProgress = (state) => {
  return state.date !== null && state.time !== null;
};

export const getFurnitures = (state) => {
  const { selectedFurnitures } = state;
  return selectedFurnitures.map((furniture) => ({
    quantity: 0,
    ...furniture,
  }));
};

/**
 * Returns all the necessary booking data
 * @param {*} state
 */
export const getBookingData = (state) => {
  const {
    date,
    time,
    pickup,
    pickupCoords,
    pickupAptUnit,
    pickupHasElevator,
    pickupFloor,
    pickupHasParking,
    pickupParkingIsNear,
    pickupDetails,
    dropoff,
    dropoffCoords,
    dropoffAptUnit,
    dropoffHasElevator,
    dropoffFloor,
    dropoffHasParking,
    dropoffParkingIsNear,
    dropoffDetails,
    comments,
    bookingType,
    selectedFurnitures,
    discount,
    discountCode,
    priceQuote,
    priceTaxed,
    mateCount,
    clientAsMate,
    justMates,
    rideAlong,
    comment,
    status,
  } = state;
  return {
    date,
    time,
    pickup,
    pickupCoords,
    pickupAptUnit,
    pickupHasElevator,
    pickupFloor,
    pickupHasParking,
    pickupParkingIsNear,
    pickupDetails,
    dropoff,
    dropoffCoords,
    dropoffAptUnit,
    dropoffHasElevator,
    dropoffFloor,
    dropoffHasParking,
    dropoffParkingIsNear,
    dropoffDetails,
    comments,
    bookingType,
    selectedFurnitures,
    discount,
    discountCode,
    priceQuote,
    priceTaxed,
    mateCount,
    clientAsMate,
    justMates,
    rideAlong,
    comment,
    status,
  };
};
