import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import EstimatePopover from '../EstimatePopover';

import {
  Wrapper,
  InfoWrapper,
  TitleText,
  ValueTextWrapper,
  CoinText,
  ValueText,
  Tax,
} from './styles';

const EstimateBar = ({ tax, editMode, tip }) => {
  const { estimate } = useSelector(({ bookingReducer, tempBookingReducer }) =>
    !editMode ? bookingReducer : tempBookingReducer
  );

  return (
    <Wrapper>
      <InfoWrapper>
        <TitleText>
          <FormattedMessage id="model.estimate" />
        </TitleText>
        <ValueTextWrapper>
          <CoinText>$</CoinText>
          <ValueText>
            {estimate &&
              (estimate.amount + (Number.parseFloat(tip) || 0)).toFixed(2)}{' '}
            {tax ? (
              <Tax>
                + ${estimate.tax.toFixed(2)}{' '}
                <FormattedMessage id="model.plusTax" />
              </Tax>
            ) : null}
          </ValueText>
          <Popover
            placement="bottomRight"
            content={<EstimatePopover />}
            trigger="click"
          >
            <QuestionCircleOutlined style={{ padding: '5px' }} />
          </Popover>
        </ValueTextWrapper>
      </InfoWrapper>
    </Wrapper>
  );
};

export default EstimateBar;
