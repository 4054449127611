import moment from 'moment';
import {
  TEMP_GET_BOOKING,
  TEMP_GET_BOOKING_ERROR,
  TEMP_GET_BOOKING_SUCCESS,
  TEMP_EDIT_BOOKING,
  TEMP_EDIT_BOOKING_ERROR,
  TEMP_EDIT_BOOKING_SUCCESS,
  TEMP_GET_VISITOR_BOOKING,
  TEMP_GET_VISITOR_BOOKING_ERROR,
  TEMP_GET_VISITOR_BOOKING_SUCCESS,
  TEMP_GET_QUOTE,
  TEMP_GET_QUOTE_SUCCESS,
  TEMP_GET_QUOTE_ERROR,
} from '../constants/tempBooking';
import { getEstimate, updateBookingData } from './booking';
import BookingService from '../../services/BookingService';
import { getLocationDetails } from '../../utils/bookingUtil';
import { getAddressAndCoords } from '../../utils/getAddressDetail';

const bookingService = new BookingService();

export const editBooking = (cipherData) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: TEMP_EDIT_BOOKING,
      });
      const { tempBookingReducer, tempMapboxReducer, geoReducer } = getState();
      const {
        bookingType,
        clientAsMate,
        comment,
        rooms,
        date,
        discount,
        discountCode,
        dropoffFloor,
        dropoffAptUnit,
        dropoffHasElevator,
        dropoffHasParking,
        dropoffParkingIsNear,
        dropoffBuildingType,
        justMates,
        mateCount,
        pickupFloor,
        pickupAptUnit,
        pickupHasElevator,
        pickupHasParking,
        pickupParkingIsNear,
        pickupBuildingType,
        rideAlong,
        estimate,
        selectedFurnitures,
        addons,
        packingBoxes = 0,
        time,
        pickup,
        dropoff,
        shortId,
        email,
        partnerAddons = [],
      } = tempBookingReducer;
      const { distance, duration } = tempMapboxReducer;
      const { tax, timezone } = geoReducer;
      const pickupLocationDetails = getLocationDetails(
        'pickup',
        pickupAptUnit,
        pickupHasElevator,
        pickupHasParking,
        pickupFloor,
        pickupBuildingType,
        pickupParkingIsNear
      );
      const dropoffLocationDetails = getLocationDetails(
        'dropoff',
        dropoffAptUnit,
        dropoffHasElevator,
        dropoffHasParking,
        dropoffFloor,
        dropoffBuildingType,
        dropoffParkingIsNear
      );
      const addonsData = { ...addons, packingBoxes, partnerAddons };
      const data = {
        travelDuration: duration / 60 || 1,
        items: selectedFurnitures,
        addons: addonsData,
        movingDate: moment(date).toISOString() || moment().toISOString(),
        discountCode,
        bookingType,
        clientAsMate,
        comment,
        rooms,
        date: moment(date).toISOString(),
        discount,
        dropoffAddress: {
          unit: dropoff.unit || '',
          street: dropoff.street || '',
          postalCode: dropoff.postalCode || '',
          city: dropoff.city || '',
          province: dropoff.province || '',
          country: dropoff.country || '',
          aptUnit: dropoffAptUnit || '',
          fullAddress: dropoff.fullAddress || '',
        },
        estimatedDistance: parseFloat(distance),
        justMates,
        locationDetails: [pickupLocationDetails, dropoffLocationDetails],
        mateCount,
        pickupAddress: {
          unit: pickup.unit || '',
          street: pickup.street || '',
          postalCode: pickup.postalCode || '',
          city: pickup.city || '',
          province: pickup.province || '',
          country: pickup.country || '',
          aptUnit: pickupAptUnit || '',
          fullAddress: pickup.fullAddress || '',
        },
        rideAlong,
        timezone,
        time: moment(time).toISOString(),
        tax: tax.rate,
        email,
        shortId,
      };
      // eslint-disable-next-line unused-imports/no-unused-vars
      const resp = await bookingService.editBooking(cipherData, data);

      window.Intercom('update', {
        bookingType: data.bookingType,
        moveDate_at: Math.floor(new Date(data.time).getTime() / 1000),
        price: estimate.amount,
      });
      dispatch({
        type: TEMP_EDIT_BOOKING_SUCCESS,
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: TEMP_EDIT_BOOKING_ERROR,
        payload: err,
      });
    }
  };
};

export const getBooking = (email, shortId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TEMP_GET_BOOKING,
      });
      const bookingResp = await bookingService.getBookingData(email, shortId);
      const cipher = bookingResp.cipher;
      const booking = transformBookingResponse(bookingResp.booking);
      dispatch({
        type: TEMP_GET_BOOKING_SUCCESS,
        payload: {
          booking: booking,
          cipher: cipher,
        },
      });
      const pickupAddress =
        bookingResp.booking.pickupAddress.fullAddress ||
        buildAddressString(bookingResp.booking.pickupAddress);
      const dropoffAddress =
        bookingResp.booking.dropoffAddress.fullAddress ||
        buildAddressString(bookingResp.booking.dropoffAddress);

      const from = await getAddressAndCoords(pickupAddress);
      const to = await getAddressAndCoords(dropoffAddress);
      dispatch(
        updateBookingData(
          {
            pickupCoords: from.coordinates,
            dropoffCoords: to.coordinates,
          },
          true
        )
      );
      dispatch(getEstimate(true));
    } catch (error) {
      console.error(error);
      dispatch({
        type: TEMP_GET_BOOKING_ERROR,
        payload: error,
      });
    }
  };
};

export const getBookingInfo = (email, shortId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TEMP_GET_VISITOR_BOOKING,
      });
      const bookingResp = await bookingService.getBookingData(email, shortId);
      const cipher = bookingResp.cipher;
      dispatch({
        type: TEMP_GET_VISITOR_BOOKING_SUCCESS,
        payload: {
          booking: bookingResp.booking,
          cipher: cipher,
        },
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: TEMP_GET_VISITOR_BOOKING_ERROR,
        payload: error,
      });
    }
  };
};

export const getQuote = (quoteId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TEMP_GET_QUOTE,
      });
      const quoteResp = await bookingService.getQuote(quoteId);
      dispatch(
        updateBookingData({
          ...quoteResp.details,
          quote: {
            id: quoteResp.id,
            email: quoteResp.email,
            shortId: quoteResp.shortId,
          },
        })
      );
      dispatch({
        type: TEMP_GET_QUOTE_SUCCESS,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: TEMP_GET_QUOTE_ERROR,
        payload: error,
      });
    }
  };
};

const transformBookingResponse = (booking) => {
  const {
    id,
    dropoffAddress,
    pickupAddress,
    locationDetails,
    bookingType,
    clientAsMate,
    addons,
    comment,
    date,
    discount,
    justMates,
    mateCount,
    rideAlong,
    items,
    rooms,
    time,
    status,
    email,
    shortId,
    estimatedPrice,
  } = booking;
  const { aptUnit: dropoffAptUnit } = dropoffAddress;
  const { aptUnit: pickupAptUnit } = pickupAddress;
  let locationInfo = {};
  locationDetails.forEach((detail) => {
    const {
      category,
      accessibility,
      parking,
      floors,
      buildingType,
      parkingIsNear,
    } = detail;
    locationInfo = {
      ...locationInfo,
      [`${category}HasElevator`]: accessibility,
      [`${category}HasParking`]: parking,
      [`${category}ParkingIsNear`]: parkingIsNear,
      [`${category}Floor`]: floors,
      [`${category}BuildingType`]: buildingType,
    };
  });
  const { packingBoxes = 0, partnerAddons, ...addonsProps } = addons;
  return {
    id,
    bookingType,
    clientAsMate,
    comment,
    date: moment(date).utc(true).toISOString(),
    discount: discount.code ? discount : null,
    discountCode: estimatedPrice.discount !== 0 ? discount.code : '',
    dropoffFloor: locationInfo.dropoffFloor,
    dropoffAptUnit,
    dropoffHasElevator: locationInfo.dropoffHasElevator,
    dropoffHasParking: locationInfo.dropoffHasParking,
    dropoffParkingIsNear: locationInfo.dropoffParkingIsNear,
    dropoffBuildingType: locationInfo.dropoffBuildingType,
    justMates,
    mateCount,
    pickupFloor: locationInfo.pickupFloor,
    pickupAptUnit,
    pickupHasElevator: locationInfo.pickupHasElevator,
    pickupHasParking: locationInfo.pickupHasParking,
    pickupParkingIsNear: locationInfo.pickupParkingIsNear,
    pickupBuildingType: locationInfo.pickupBuildingType,
    rideAlong,
    rooms,
    selectedFurnitures: items,
    addons: !addonsProps ? {} : addonsProps,
    packingBoxes,
    partnerAddons: partnerAddons || [],
    time: moment(time).utc(true).toISOString(),
    pickup: pickupAddress,
    dropoff: dropoffAddress,
    status,
    email,
    shortId,
  };
};

export const buildAddressString = (location) => {
  if (
    location.street &&
    location.city &&
    location.province &&
    location.postalCode &&
    location.country
  ) {
    return `${location.unit} ${location.street}, ${location.city}, ${location.province} ${location.postalCode}, ${location.country}`;
  }
  return '';
};
