import axios from 'axios';

export default class B2CPartnerService {
  /**
   * Gets the list of partners
   * @param {string} accessToken
   */
  getB2CPartner(accessToken) {
    return axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/b2c/partner`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((resp) => resp.data);
  }

  /**
   * Gets the list of partners
   * @param {string} partnerId
   * @param {string} accessToken
   */
  getB2CPartnerById(partnerId, accessToken) {
    return axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/b2c/partner/${partnerId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((resp) => resp.data);
  }

  /**
   * Creates a partner
   * @param {obj} partnerData The data to create partner
   *                      name {string} the partner name
   *                      description {string} the partner description
   *                      discountCode {string} the partner discountCode
   *                      cities {array} the partner cities
   *                      url {string} the partner url
   *                      logo {string} the partner logo
   * @param {string} accessToken The current user access token to ping the backend
   */
  createB2CPartner(partnerData, accessToken) {
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/b2c/partner`,
      partnerData,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }
  /**
   * Update a partner
   * @param {obj} partnerData The data to create partner
   *                      partnerId {string} the partner id
   *                      name {string} the partner name
   *                      description {string} the partner description
   *                      discountCode {string} the partner discountCode
   *                      cities {array} the partner cities
   *                      url {string} the partner url
   *                      logo {string} the partner logo
   * @param {string} accessToken The current user access token to ping the backend
   */
  updateB2CPartner(partnerId, partnerData, accessToken) {
    return axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/b2c/partner/${partnerId}`,
      partnerData,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }
  /**
   * Delete a partner
   * @param {obj} partnerData The data to delete partner
   *                      partnerId {string} the partner id
   * @param {string} accessToken The current user access token to ping the backend
   */
  deleteB2CPartner(partnerId, accessToken) {
    return axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/b2c/partner/${partnerId}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }
}
