import {
  CLEAR_MATE,
  CREATE_MATE,
  CREATE_MATE_SUCCESS,
  CREATE_MATE_ERROR,
  GET_MATE,
  GET_MATE_SUCCESS,
  GET_MATE_ERROR,
  GET_MATE_BY_ID,
  GET_MATE_BY_ID_SUCCESS,
  GET_MATE_BY_ID_ERROR,
  UPDATE_MATE,
  UPDATE_MATE_SUCCESS,
  UPDATE_MATE_ERROR,
  GET_ONFLEET_MATES,
  GET_ONFLEET_MATES_SUCCESS,
  GET_ONFLEET_MATES_ERROR,
  DELETE_MATE,
  DELETE_MATE_SUCCESS,
  DELETE_MATE_ERROR,
} from '../constants/mate';
import MateService from '../../services/MateService';

const mateService = new MateService();

/**
 * Reset mate viewedMate
 */
export const resetMate = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_MATE });
  };
};

/**
 * Gets the list of mates
 * @param {string} accessToken
 */
export const getMates = (accessToken, page = 0, perPage = 15) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_MATE,
      });
      const resp = await mateService.getMate(accessToken, page, perPage);
      dispatch({
        type: GET_MATE_SUCCESS,
        mates: resp.data,
        total: resp.total,
      });
    } catch (error) {
      dispatch({
        type: GET_MATE_ERROR,
        payload: error,
      });
    }
  };
};
/**
 * Gets the list of mates
 * @param {string} accessToken
 */
export const getOnfleetMates = (accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_ONFLEET_MATES,
      });
      const resp = await mateService.getOnfleetMate(accessToken);
      dispatch({
        type: GET_ONFLEET_MATES_SUCCESS,
        workers: resp.data,
      });
    } catch (error) {
      dispatch({
        type: GET_ONFLEET_MATES_ERROR,
        payload: error,
      });
    }
  };
};
/**
 * Gets a mate by ID
 * @param {string} mateId
 * @param {string} accessToken
 */
export const getMateById = (mateId, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_MATE_BY_ID,
      });
      const resp = await mateService.getMateById(mateId, accessToken);
      dispatch({
        type: GET_MATE_BY_ID_SUCCESS,
        data: resp,
      });
    } catch (error) {
      dispatch({
        type: GET_MATE_BY_ID_ERROR,
        payload: error,
      });
    }
  };
};
/**
 * Creates a mate
 * @param {obj} mateData The data to create mate
 *                      name {string} the mate name
 *                      addresses {array[Address]} the address of the mate
 * @param {string} accessToken Access token obtained from auth0 to check if user is authorized to access endpoint
 * @returns
 */
export const createMate = (mateData, accessToken) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_MATE,
      loading: true,
    });
    try {
      const resp = await mateService.createMate(mateData, accessToken);
      dispatch({
        type: CREATE_MATE_SUCCESS,
        payload: resp.data,
      });
    } catch (err) {
      dispatch({
        type: CREATE_MATE_ERROR,
        error: err,
      });
    }
  };
};
/**
 * Update a mate
 * @param {obj} mateData The data to update mate
 *                      id {string} the mate id
 *                      name {string} the mate name
 *                      addresses {array[Address]} the address of the mate
 * @param {string} accessToken Access token obtained from auth0 to check if user is authorized to access endpoint
 * @returns
 */
export const updateMate = (mateId, mateData, accessToken) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_MATE,
      loading: true,
    });
    try {
      const resp = await mateService.updateMate(mateId, mateData, accessToken);
      dispatch({
        type: UPDATE_MATE_SUCCESS,
        payload: resp.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_MATE_ERROR,
        error: err,
      });
    }
  };
};

/**
 * Delete a mate
 * @param {string} mateId {string} the mate id
 * @param {string} accessToken Access token obtained from auth0 to check if user is authorized to access endpoint
 * @returns
 */
export const deleteMate = async (mateId, accessToken) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_MATE,
      loading: true,
    });
    try {
      const resp = await mateService.deleteMate(mateId, accessToken);
      dispatch({
        type: DELETE_MATE_SUCCESS,
        payload: resp.data,
      });
    } catch (err) {
      dispatch({
        type: DELETE_MATE_ERROR,
        error: err,
      });
    }
  };
};
