export const bookingTypes = {
  itemDelivery: "itemDelivery", // smallMove
  moves: "moves", // residential
};

export const furnitureCategories = [
  "livingRoom",
  "kitchenDiningRoom",
  "largeAppliances",
  "bedroomBathroom",
  "outdoorSports",
  "binContainerBox",
];

export const roomItems = {
  "masterBedroom": [
    {
      "id": "fullQueenBed",
      "quantity": 1,
    },
    {
      "id": "dresser6Drawer",
      "quantity": 1,
    },
    {
      "id": "nightstandBedsideTable",
      "quantity": 2,
    },
    {
      "id": "wardrobeArmoire",
      "quantity": 1,
    },
    {
      "id": "tv",
      "quantity": 1,
    },
    {
      "id": "mirrorLarge",
      "quantity": 1,
    },
    {
      "id": "rugLargeRoll",
      "quantity": 1,
    },
  ],
  "bedroom": [
    {
      "id": "fullQueenBed",
      "quantity": 1,
    },
    {
      "id": "dresser6Drawer",
      "quantity": 1,
    },
    {
      "id": "nightstandBedsideTable",
      "quantity": 2,
    },
    {
      "id": "tv",
      "quantity": 1,
    },
    {
      "id": "mirrorLarge",
      "quantity": 1,
    },
    {
      "id": "rugLargeRoll",
      "quantity": 1,
    },
  ],
  "livingRoom": [
    {
      "id": "couchSofa4/5Seat",
      "quantity": 1,
    },
    {
      "id": "coffeeTable",
      "quantity": 1,
    },
    {
      "id": "endTable",
      "quantity": 2,
    },
    {
      "id": "tv",
      "quantity": 1,
    },
    {
      "id": "tvStand",
      "quantity": 1,
    },
    {
      "id": "bookshelfShort",
      "quantity": 1,
    },
    {
      "id": "rugLargeRoll",
      "quantity": 1,
    },
    {
      "id": "floorLamp",
      "quantity": 2,
    },
    {
      "id": "ottoman",
      "quantity": 2,
    },
  ],
  "diningRoom": [
    {
      "id": "diningTable4Seater",
      "quantity": 1,
    },
    {
      "id": "highBarChair",
      "quantity": 4,
    },
    {
      "id": "buffet",
      "quantity": 1,
    },
    {
      "id": "mirrorSmall",
      "quantity": 1,
    },
    {
      "id": "rugLargeRoll",
      "quantity": 1,
    },
  ],
  "homeOffice": [
    {
      "id": "desk",
      "quantity": 1,
    },
    {
      "id": "deskOfficeChair",
      "quantity": 1,
    },
    {
      "id": "computerMonitor",
      "quantity": 1,
    },
    {
      "id": "fileCabinet3Drawer",
      "quantity": 1,
    },
  ],
  "storageLocker": [
    {
      "id": "bicycle",
      "quantity": 1,
    },
    {
      "id": "suitcaseLarge",
      "quantity": 2,
    },
    {
      "id": "tire",
      "quantity": 4,
    },
  ],
  "gymEquipment": [
    {
      "id": "weightBenchRack",
      "quantity": 1,
    },
    {
      "id": "weightsDumbells",
      "quantity": 1,
    },
    {
      "id": "exerciseBand/Balls",
      "quantity": 1,
    },
    {
      "id": "workoutAccessories",
      "quantity": 1,
    },
  ],
  "balconyPatio": [
    {
      "id": "patioTable",
      "quantity": 1,
    },
    {
      "id": "patioUmbrella",
      "quantity": 1,
    },
    {
      "id": "patioChair",
      "quantity": 4,
    },
    {
      "id": "bbq",
      "quantity": 1,
    },
  ],
}

export const largeAppliances = [
  {
    "id": "washingMachine",
    "quantity": 1
  },
  {
    "id": "dryer",
    "quantity": 1
  },
  {
    "id": "stove",
    "quantity": 1
  },
  {
    "id": "refrigerator",
    "quantity": 1
  },
  {
    "id": "dishwasher",
    "quantity": 1
  },
  {
    "id": "treadmill",
    "quantity": 1
  },
]

export const DEFAULT_BOX = 'boxMedium'
export const PHONE_REGEXP = /^((\\+[1-9]{1,4}[ \\-]*)?|(\\([0-9]{2,3}\\)[ \\-]*)|(\(?[0-9]{2,4}\)?)[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;