import axios from 'axios';

export default class CalendarService {
  /**
   * Gets the calendar from google service
   * @param {string} accessToken
   */
  getCalendarFromGoogleService(accessToken) {
    return axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/calendar/google`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((resp) => resp.data);
  }
  /**
   * Gets the list of calendar
   * @param {string} accessToken
   */
  getCalendar(accessToken, page = 0, limit = 200, query = '') {
    return axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/calendar?page=${page}&per_page=${limit}` +
          (query ? `&${query}` : ''),
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((resp) => resp.data);
  }

  /**
   * Gets the calendar by id
   * @param {string} calendarId
   * @param {string} accessToken
   */
  getCalendarById(calendarId, accessToken) {
    return axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/calendar/${calendarId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((resp) => resp.data);
  }

  /**
   * Creates a calendar
   * @param {obj} calendarData The data to create calendar
   *                      name {string} Name of the Calendar
   *                      coordinates {array[float, float]} list of coordinate in [Long, Lat]
   *                      countryCode {string} The Country Standard Code (ref: ISO 3166 / ALPHA-2)
   *                      stateCode {string} The State/Province Standard Code (ref: ISO 3166 / ALPHA-2)
   *                      maxDistance {float} Maximum driving distance in KM
   * @param {string} accessToken The current user access token to ping the backend
   */
  createCalendar(calendarData, accessToken) {
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/calendar`,
      calendarData,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }
  /**
   * Update a calendar
   * @param {string} calendarId calendarId {string} the calendar id
   * @param {obj} calendarData The data to create calendar
   *                      name {string} Name of the Calendar
   *                      coordinates {array[float, float]} list of coordinate in [Long, Lat]
   *                      countryCode {string} The Country Standard Code (ref: ISO 3166 / ALPHA-2)
   *                      stateCode {string} The State/Province Standard Code (ref: ISO 3166 / ALPHA-2)
   *                      maxDistance {float} Maximum driving distance in KM
   * @param {string} accessToken The current user access token to ping the backend
   */
  updateCalendar(calendarId, calendarData, accessToken) {
    return axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/calendar/${calendarId}`,
      calendarData,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }
  /**
   * Delete a calendar
   * @param {obj} calendarData The data to delete calendar
   *                      calendarId {string} the calendar id
   * @param {string} accessToken The current user access token to ping the backend
   */
  deleteCalendar(calendarId, accessToken) {
    return axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/calendar/${calendarId}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }
}
