import React from 'react';
import PropTypes from 'prop-types';

import { Button } from './styles';

const SquareButton = ({
  onClick,
  children,
  disabled,
  variant,
  type,
  ...props
}) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      type={type}
      variant={variant}
      className="mm-square-button"
      {...props}
    >
      {children}
    </Button>
  );
};

SquareButton.defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick: () => {},
  disabled: false,
  type: 'submit',
};

SquareButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
};

export default SquareButton;
