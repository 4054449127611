import axios from 'axios';

export default class B2BPartnerService {
  /**
   * Gets the list of partners
   * @param {string} accessToken
   */
  getB2BPartner(accessToken) {
    return axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/b2b/partner`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((resp) => resp.data);
  }

  /**
   * Gets the list of partners
   * @param {string} partnerId
   * @param {string} accessToken
   */
  getB2BPartnerById(partnerId, accessToken) {
    return axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/b2b/partner/${partnerId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((resp) => resp.data);
  }

  /**
   * Creates a partner
   * @param {obj} partnerData The data to create partner
   *                      id {string} the partner id
   *                      name {string} the partner name
   *                      addresses {array[Address]} the address of the partner
   * @param {string} accessToken The current user access token to ping the backend
   */
  createB2BPartner(partnerData, accessToken) {
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/b2b/partner`,
      partnerData,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }
  /**
   * Update a partner
   * @param {obj} partnerData The data to create partner
   *                      partnerId {string} the partner id
   *                      name {string} the partner name
   *                      addresses {array[Address]} the address of the partner
   * @param {string} accessToken The current user access token to ping the backend
   */
  updateB2BPartner(partnerId, partnerData, accessToken) {
    return axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/b2b/partner/${partnerId}`,
      partnerData,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
  }
}
