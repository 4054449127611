import styled from 'styled-components';
import { Row, Image } from 'antd';
import SquareButton from '../SquareButton';

export const ContentWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100vw;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .mm-alert {
    background-color: var(--primary-color-4);
    border-color: var(--primary-color);
  }
`;

export const Button = styled(SquareButton)`
  margin: 0.6em 0;
  font-weight: 500;
  background-color: var(--secondary-color-blue);
`;

export const Logo = styled(Image)`
  width: 100px;
  margin-left: 10px;

  @media (max-width: 1024px) {
    width: 90px;
  }
  @media (max-width: 924px) {
    display: none;
  }
`;
export const Header = styled.div`
  background-color: var(--container-color);
  border-bottom: 1px solid var(--border-color);
  min-height: 90px;
`;

export const StepWrapper = styled.div`
  gap: 5px;
  display: flex;
  margin: 0 15px;
  align-items: center;
  span {
    color: #026a97;
  }
  div:first-child {
    margin-left: 10px;
  }
  div:last-child {
    margin-right: 10px;
  }
  @media (max-width: 820px) {
    label {
      display: none;
    }
  }
`;

export const StepProgress = styled.div`
  width: 37px;
  height: 10px;
  display: block;
  border-radius: 10px;
  background-color: ${({ active }) =>
    active ? 'var(--secondary-color-blue)' : '#CCE1EA'};
  border: ${({ border }) =>
    border ? '2px solid var(--border-color)' : 'none'};
  cursor: ${({ active }) => (active ? 'pointer' : 'inherit')};
`;

export const HeaderTitle = styled.div`
  h3 {
    margin-bottom: 0;
    font-size: 2rem;
    display: flex;
    align-items: center;
    span {
      font-size: 0.8rem;
      margin-left: 5px;
    }
  }
  @media (max-width: 1024px) {
    h3 {
      font-size: 1.1rem;
    }
  }
  @media (max-width: 820px) {
    display: none;
  }
`;

export const SmallHeaderTitle = styled.p`
  font-size: 0.8rem;
  margin-right: 10px;
  margin-bottom: 0;
  @media (min-width: 821px) {
    display: none;
  }
`;

export const Body = styled.div`
  position: relative;
  margin: 0 auto;
  flex: 1;
  width: 95%;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: transparent;
`;

export const BodyVerticalWrapper = styled.div`
  width: 100%;
  height: 100%;

  @media (max-width: 300px) {
    width: 100vw;
  }
`;

export const BodyWrapper = styled.div`
  top: 0;
  position: absolute;
  display: table;
  height: 100%;
  width: 100%;
`;

export const ButtonGroupWrapper = styled.div`
  @media (max-width: 1024px) {
    position: fixed;
    bottom: 0;
    background: var(--container-color);
    width: 100%;
    z-index: 2;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 15px 0px;
  padding: 0 90px;

  & > button {
    margin: 0 0.5rem;
    max-width: 200px;
  }
`;

export const HeaderContentRow = styled(Row)`
  @media (max-width: 821px) {
    .ant-row:first-child {
      display: none;
    }
  }
`;

export const VerticalLine = styled.div`
  top: -18px;
  height: 90px;
  position: absolute;
  display: inline-block;
  border-right: 1px solid #e9eaea;
  @media (max-width: 1025px) {
    display: none;
  }
`;
export const MenuProfile = styled.div`
  padding: 10px 20px;
  width: calc(100% + 30px);
  min-width: 120px;
  max-width: 300px;
  background-color: #fff;
  border: 1px solid #e9eaea;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: var(--box-shadow);
  .ant-divider-horizontal {
    margin: 5px 0;
  }
  @media (max-width: 900px) {
    width: auto;
  }
`;
export const UserContent = styled.div`
  width: 100%;
  display: inline-block;
  h3 {
    margin: 0;
    font-weight: 700;
    font-size: 1.1rem;
  }
  label {
    color: #5e6665;
    font-weight: 400;
    font-size: 0.8rem;
  }
  * {
    padding: 5px;
  }
  *:hover {
    opacity: 1;
    background-color: transparent;
  }
`;
export const MenuOptions = styled.div`
  cursor: pointer;
  img {
    width: auto;
    height: 20px;
    margin-right: 10px;
    padding: 0px;
  }
  p {
    margin-bottom: 10px;
    border-radius: var(--border-radius);
  }
  * {
    padding: 5px;
    width: 100%;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
  }
  *:hover {
    opacity: 0.7;
    background-color: #f2f7f9;
  }
`;
export const RowProfile = styled(Row)`
  margin-left: 1rem;
  align-items: center;
  margin-right: 20px;
  .mm-profile-icon {
    margin-right: 10px;
    padding: 2px;
    border: 1px solid;
    font-size: 1.3rem;
    border-radius: 50%;
  }
  .mm-down-icon {
    font-size: 1.2rem;
  }
`;
export const AntRow = styled(Row)`
  margin: 0.5rem 2rem;
  align-items: center;
  @media (max-width: 1025px) {
    margin: 1rem 0.8rem;
  }
  @media (min-width: 870px) {
    .anticon-left-circle {
      display: none;
    }
  }
  @media (max-width: 870px) {
    .mm-sm-menu {
      width: 100%;
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      justify-content: space-between;
      .anticon-left-circle {
        font-size: 1rem;
      }
      .ant-dropdown-trigger {
        display: flex;
        flex-flow: nowrap;
        margin: 0;
      }
    }
    .mm-lg-menu {
      .ant-dropdown-trigger {
        display: none;
      }
    }
  }
  @media (min-width: 900px) {
    .mm-sm-menu {
      .ant-dropdown-trigger {
        display: none;
      }
    }
    .mm-lg-menu {
      .ant-dropdown-trigger {
        display: inline-block;
      }
    }
  }
  @media (max-width: 820px) {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
  }
  @media (max-width: 500px) {
    margin: 0.5rem 1rem;
  }
`;

export const Fragment = styled.div`
  margin-top: ${(props) => `${props.marginTop}%`};
`;

export const HeaderWrapper = styled.div`
  text-align: center;
`;

export const HeaderAdBlockText = styled.h1``;

export const ImageMan = styled.img`
  max-height: 24vh;
`;

export const TitleText = styled.p`
  font-size: 2em;
  font-weight: bold;
  color: var(--tertiary-color);
  margin: 0;

  @media (max-width: 1024px) {
    font-size: 1.5em;
  }
`;
