const ADDRESS_KEY = Object.entries({
  'unit': 'street_number',
  'city': 'locality',
  'postalCode': 'postal_code',
  'street': 'route',
  'country': 'country',
  'province': 'administrative_area_level_1',
})

export default function convertMapsToAddress(addressDetail) {
  const location = {
    city: '',
    country: '',
    fullAddress: addressDetail.formatted_address,
    postalCode: '',
    province: '',
    street: '',
    unit: '',
  }
  if (addressDetail && addressDetail.address_components) {
    location.fullAddress = addressDetail.formatted_address
    addressDetail.address_components.forEach(address => {
      ADDRESS_KEY.forEach(([locationKey, mapsKey]) => {
        if (address.types.includes(mapsKey)) {
          location[locationKey] = address.long_name
        }
      })
    })
  }
  return location
}