import { CHANGE_LOCALE, OPEN_LOCALE_MENU } from '../constants/language';

export const changeLocale = (languageLocale) => ({
  type: CHANGE_LOCALE,
  locale: languageLocale,
});

export const handleOpenMenu = (open = false) => ({
  type: OPEN_LOCALE_MENU,
  menu: open,
});
