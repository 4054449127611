import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, useLocation, useHistory, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Layout, Col, Row, Dropdown, Divider } from 'antd';

import {
  AntRow,
  ContentWrapper,
  Fragment,
  Logo,
  HeaderWrapper,
  ImageMan,
  TitleText,
  Body,
  Header,
  ButtonGroup,
  ButtonGroupWrapper,
  BodyWrapper,
  BodyVerticalWrapper,
  HeaderTitle,
  StepProgress,
  StepWrapper,
  HeaderContentRow,
  VerticalLine,
  SmallHeaderTitle,
  RowProfile,
  MenuProfile,
  UserContent,
  MenuOptions,
  Button,
} from './styles.js';

import EstimateBar from '../EstimateBar';
import {
  changeLocale,
  clearStore,
  handleOpenMenu,
} from 'sharedlibs/lib/redux/index.js';
import {
  CheckCircleFilled,
  DownOutlined,
  LeftCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { stepDetails } from './stepDetails.js';

const { Sider, Content } = Layout;

const pagesLength = Object.values(stepDetails).filter(
  ({ showStep }) => showStep
).length;

const MainWrapper = ({
  children,
  editMode,
  logoPath,
  logoImage,
  onlyLogo,
  logoPositioning,
  showImage,
  imageDescription,
  nextButton,
  backButton,
  tax,
  tip,
  removeStepper,
  removePrice,
  removeTitle,
  handleLogin,
  doLogin,
}) => {
  const intl = useIntl();
  const { logout } = useAuth0();
  const dispatch = useDispatch();
  const history = useHistory();
  const { bookingId = '' } = useParams();
  const { hexData = '' } = useParams();
  const { pathname } = useLocation();
  const pathCleaned = pathname.split('/')[1];
  const { bookingInProgress, ...booking } = useSelector(
    ({ bookingReducer }) => bookingReducer
  );
  const editInProgress = useSelector(
    ({ tempBookingReducer }) => tempBookingReducer.bookingInProgress
  );

  const user = useSelector(({ userReducer }) => userReducer);
  const menuOpened = useSelector(({ languageReducer }) => languageReducer.menu);

  useEffect(() => {
    window.addEventListener('beforeunload', alertOnLeave);
    return () => {
      window.removeEventListener('beforeunload', alertOnLeave);
    };
  }, []);

  const alertOnLeave = useCallback((e) => {
    if ((!editMode && bookingInProgress) || (editMode && editInProgress)) {
      e.preventDefault();
      e.returnValue = '';
    }
  }, []);

  useEffect(() => {
    if (doLogin) {
      window.removeEventListener('beforeunload', alertOnLeave);
      handleLogin();
    }
  }, [doLogin]);
  function handleChangeLocale() {
    dispatch(changeLocale(intl.locale === 'en' ? 'fr' : 'en'));
  }
  function handleLogout() {
    dispatch(clearStore());
    logout({ returnTo: window.location.origin });
  }

  const renderProfileMenu = (
    <Dropdown
      dropdownRender={() => {
        const userLogged = !!user.email;
        return (
          <MenuProfile>
            {user.firstName ? (
              <UserContent>
                <h3>{user.firstName}</h3>
                <label>{user.email}</label>
                <Divider />
              </UserContent>
            ) : (
              <Button onClick={() => history.push('/booking/view')}>
                <FormattedMessage id="model.manageBooking" />
              </Button>
            )}
            <MenuOptions>
              {userLogged && (
                <React.Fragment>
                  <p onClick={() => history.push('/profile')}>
                    <img src="/icons/new/account_circle.svg" />
                    <FormattedMessage id="model.yourAccount" />
                  </p>
                  <p onClick={() => history.push('/profile/settings')}>
                    <img src="/icons/new/settings.svg" />
                    <FormattedMessage id="model.profileSettings" />
                  </p>
                  <Divider />
                </React.Fragment>
              )}
              <p onClick={handleChangeLocale}>
                <img src="/icons/new/g_translate.svg" />
                {intl.locale === 'en' ? 'Français' : 'English'}
              </p>
              {userLogged ? (
                <p onClick={handleLogout}>
                  <FormattedMessage id="auth.logout" />
                </p>
              ) : (
                <p onClick={() => history.push('/profile')}>
                  <FormattedMessage id="auth.signin" />
                </p>
              )}
            </MenuOptions>
          </MenuProfile>
        );
      }}
      trigger={['click', 'hover']}
    >
      <RowProfile>
        <UserOutlined className="mm-profile-icon" />
        <DownOutlined className="mm-down-icon" />
      </RowProfile>
    </Dropdown>
  );

  const renderHeader = () => {
    const activePaths = {
      '/locations/':
        !!booking?.dropoff.fullAddress && !!booking?.pickup.fullAddress,
      '/move-type/': !!booking?.selectedFurnitures.length,
      '/schedule/': !!booking?.time,
      '/locations-details/': !!booking?.pickupHasElevator,
      '/add-ons/': !!booking?.addonViewed,
    };
    const removeHeaderFrom = [
      '/user-info',
      '/payment',
      '/profile',
      '/thank-you',
      '/profile/settings',
      '/profile/bookings',
      '/profile/bookings/',
    ];
    if (onlyLogo) {
      return (
        <Col style={{ marginLeft: 20, marginTop: 10 }}>
          <Link to={logoPath}>
            <Logo
              src={logoImage}
              preview={false}
              between={logoPositioning !== 'logoCenter' ? 1 : 0}
            />
          </Link>
        </Col>
      );
    }
    if (removeHeaderFrom.includes(pathCleaned)) {
      return <div style={{ marginBottom: 30, width: '100%' }}></div>;
    } else {
      return (
        <>
          {logoPositioning !== 'none' && (
            <Header>
              <HeaderContentRow
                justify={
                  logoPositioning !== 'logoCenter' ? 'space-between' : 'center'
                }
                between={logoPositioning !== 'logoCenter' ? 1 : 0}
              >
                <AntRow>
                  <Col>
                    <Link to={logoPath}>
                      <Logo
                        src={logoImage}
                        preview={false}
                        between={logoPositioning !== 'logoCenter' ? 1 : 0}
                      />
                    </Link>
                  </Col>
                  {!removeTitle &&
                  stepDetails[pathCleaned] &&
                  stepDetails[pathCleaned].title ? (
                    <Col>
                      <HeaderTitle>
                        <h3>
                          <FormattedMessage
                            id={stepDetails[pathCleaned]?.title}
                          />
                        </h3>
                      </HeaderTitle>
                    </Col>
                  ) : null}
                </AntRow>
                <AntRow>
                  {!removeStepper && (
                    <Col>
                      <VerticalLine style={{ top: '-35px' }} />
                      <StepWrapper>
                        {stepDetails[pathCleaned] &&
                        stepDetails[pathCleaned].title ? (
                          <SmallHeaderTitle>
                            <FormattedMessage
                              id={stepDetails[pathCleaned].title}
                            />
                          </SmallHeaderTitle>
                        ) : null}
                        <label>
                          <FormattedMessage id={'model.progress'} />
                        </label>
                        {Object.values(stepDetails)
                          .filter(({ showStep }) => showStep)
                          .map((stepDetail) => {
                            const stepData = stepDetails[pathCleaned] || {};
                            return (
                              <StepProgress
                                key={stepDetail.step}
                                active={
                                  !!bookingId ||
                                  !!hexData ||
                                  activePaths[stepDetail.link] ||
                                  stepDetail.step <= stepData.step
                                }
                                border={stepDetail.step <= stepData.step}
                                onClick={() =>
                                  history.push(stepDetail.link + bookingId)
                                }
                              />
                            );
                          })}
                        {stepDetails[pathCleaned] &&
                        stepDetails[pathCleaned].step < 7 ? (
                          stepDetails[pathCleaned].step + '/' + pagesLength
                        ) : (
                          <CheckCircleFilled style={{ fontSize: '1.1rem' }} />
                        )}
                      </StepWrapper>
                    </Col>
                  )}
                  {!removePrice && (
                    <Col className="mm-sm-menu">
                      <VerticalLine />
                      <LeftCircleOutlined onClick={() => history.goBack()} />
                      <EstimateBar
                        tax={tax}
                        tip={tip}
                        editMode={editMode}
                      />
                      {renderProfileMenu}
                    </Col>
                  )}
                  <Col className="mm-lg-menu">
                    <VerticalLine style={{ top: -30 }} />
                    {renderProfileMenu}
                  </Col>
                </AntRow>
              </HeaderContentRow>
            </Header>
          )}
        </>
      );
    }
  };

  const renderImage = () => {
    return (
      <>
        <Fragment>
          <Row justify="center">
            <Col>
              <HeaderWrapper>
                {showImage && <ImageMan src="/icons/movemateMan.png" />}
                {imageDescription && (
                  <TitleText>
                    <FormattedMessage id={imageDescription} />
                  </TitleText>
                )}
              </HeaderWrapper>
            </Col>
          </Row>
        </Fragment>
      </>
    );
  };

  const renderBody = () => {
    return (
      <Body>
        <BodyWrapper>
          <BodyVerticalWrapper>{children}</BodyVerticalWrapper>
        </BodyWrapper>
      </Body>
    );
  };

  const renderButtons = () => {
    return (
      <>
        {logoPositioning !== 'none' && (
          <ButtonGroupWrapper>
            <ButtonGroup>
              {backButton}
              {nextButton}
            </ButtonGroup>
          </ButtonGroupWrapper>
        )}
      </>
    );
  };

  return (
    <Layout style={{ height: '100%' }}>
      <Sider
        collapsible
        trigger={null}
        breakpoint="lg"
        collapsedWidth="0"
        collapsed={!menuOpened}
      >
        {/* <Menu
          mode="inline"
          items={[
            {
              key: 'profile',
              label: <Link to='/profile'>My Profile</Link>,
              type: 'group'
            },
            {
              key: 'lang',
              label: <Menu
                mode='inline'
                defaultChecked={null}
                items={[{
                  key: 'en',
                  label: 'en'
                }, {
                  key: 'fr',
                  label: 'fr'
                }]} />,
              type: 'group'
            }
          ]}
        /> */}
      </Sider>
      <Content
        className="container"
        onClick={() => dispatch(handleOpenMenu(false))}
      >
        <ContentWrapper
          style={{ width: menuOpened ? 'calc(100vw - 200px)' : '100vw' }}
        >
          {renderHeader()}
          {renderImage()}
          {renderBody()}
          {renderButtons()}
        </ContentWrapper>
      </Content>
    </Layout>
  );
};

MainWrapper.defaultProps = {
  logoPath: '/',
  onlyLogo: false,
  removeTitle: false,
  removeStepper: false,
  removePrice: false,
  logoImage: '/movemate_logo.svg',
  logoPositioning: 'logoXestimate',
  showImage: false,
  imageDescription: '',
  nextButton: null,
  backButton: null,
  stepper: false,
  editMode: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onStepSelect: () => {},
  viewStepperMessage: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setViewStepperMessage: () => {},
  showRedirectAlert: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleLogin: () => {},
  doLogin: false,
};

MainWrapper.propTypes = {
  logoPath: PropTypes.string,
  logoImage: PropTypes.string,
  logoPositioning: PropTypes.oneOf([
    'logoCenter',
    'logoXestimate',
    'logoLeft',
    'none',
  ]),
  showImage: PropTypes.bool,
  imageDescription: PropTypes.string,
  editMode: PropTypes.bool,
  onStepSelect: PropTypes.func,
  viewStepperMessage: PropTypes.bool,
  setViewStepperMessage: PropTypes.func,
  handleLogin: PropTypes.func,
  doLogin: PropTypes.bool,
};

export default MainWrapper;
