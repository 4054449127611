import {
  CLEAR_CALENDAR,
  CREATE_CALENDAR,
  CREATE_CALENDAR_SUCCESS,
  CREATE_CALENDAR_ERROR,
  GET_CALENDAR,
  GET_CALENDAR_SUCCESS,
  GET_CALENDAR_ERROR,
  GET_CALENDAR_BY_ID,
  GET_CALENDAR_BY_ID_SUCCESS,
  GET_CALENDAR_BY_ID_ERROR,
  UPDATE_CALENDAR,
  UPDATE_CALENDAR_SUCCESS,
  UPDATE_CALENDAR_ERROR,
  DELETE_CALENDAR,
  DELETE_CALENDAR_SUCCESS,
  DELETE_CALENDAR_ERROR,
} from '../constants/calendar';
import CalendarService from '../../services/CalendarService';

const calendarService = new CalendarService();

/**
 * Reset calendar viewedPartner
 */
export const resetCalendar = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_CALENDAR });
  };
};

/**
 * Gets the list of calendars
 * @param {string} accessToken
 */
export const getCalendar = (accessToken, currentPage, page) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_CALENDAR,
      });
      const googleService = await calendarService.getCalendarFromGoogleService(
        accessToken
      );
      const resp = await calendarService.getCalendar(
        accessToken,
        currentPage,
        page
      );
      dispatch({
        type: GET_CALENDAR_SUCCESS,
        calendars: resp.data,
        googleServiceCalendars: googleService.data,
        count: resp.count,
      });
    } catch (error) {
      dispatch({
        type: GET_CALENDAR_ERROR,
        payload: error,
      });
    }
  };
};

/**
 * Gets a calendar by ID
 * @param {string} calendarId
 * @param {string} accessToken
 */
export const getCalendarById = (calendarId, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_CALENDAR_BY_ID,
      });
      const resp = await calendarService.getCalendarById(
        calendarId,
        accessToken
      );
      dispatch({
        type: GET_CALENDAR_BY_ID_SUCCESS,
        data: resp,
      });
    } catch (error) {
      dispatch({
        type: GET_CALENDAR_BY_ID_ERROR,
        payload: error,
      });
    }
  };
};
/**
 * Creates a calendar
 * @param {obj} calendarData The data to create calendar
 *                       name {string} Name of the Calendar
 *                       coordinates {array[float, float]} list of coordinate in [Long, Lat]
 *                       countryCode {string} The Country Standard Code (ref: ISO 3166 / ALPHA-2)
 *                       stateCode {string} The State/Province Standard Code (ref: ISO 3166 / ALPHA-2)
 *                       maxDistance {float} Maximum driving distance in KM
 * @param {string} accessToken Access token obtained from auth0 to check if user is authorized to access endpoint
 * @returns
 */
export const createCalendar = (calendarData, accessToken) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_CALENDAR,
      loading: true,
    });
    try {
      const resp = await calendarService.createCalendar(
        calendarData,
        accessToken
      );
      dispatch({
        type: CREATE_CALENDAR_SUCCESS,
        payload: resp.data,
      });
    } catch (err) {
      dispatch({
        type: CREATE_CALENDAR_ERROR,
        error: err,
      });
      throw new Error(`Error ${JSON.stringify(err.toJSON().message)}`);
    }
  };
};
/**
 * Update a calendar
 * @param {obj} calendarData The data to create calendar
 *                      name {string} Name of the Calendar
 *                      coordinates {array[float, float]} list of coordinate in [Long, Lat]
 *                      countryCode {string} The Country Standard Code (ref: ISO 3166 / ALPHA-2)
 *                      stateCode {string} The State/Province Standard Code (ref: ISO 3166 / ALPHA-2)
 *                      maxDistance {float} Maximum driving distance in KM
 * @param {string} accessToken Access token obtained from auth0 to check if user is authorized to access endpoint
 * @returns
 */
export const updateCalendar = (calendarId, calendarData, accessToken) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_CALENDAR,
      loading: true,
    });
    try {
      const resp = await calendarService.updateCalendar(
        calendarId,
        calendarData,
        accessToken
      );
      dispatch({
        type: UPDATE_CALENDAR_SUCCESS,
        payload: resp.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_CALENDAR_ERROR,
        error: err,
      });
      throw new Error(`Error ${JSON.stringify(err.toJSON().message)}`);
    }
  };
};

/**
 * Update a calendar
 * @param {obj} calendarData The data to create calendar
 *                      name {string} Name of the Calendar
 *                      coordinates {array[float, float]} list of coordinate in [Long, Lat]
 *                      countryCode {string} The Country Standard Code (ref: ISO 3166 / ALPHA-2)
 *                      stateCode {string} The State/Province Standard Code (ref: ISO 3166 / ALPHA-2)
 *                      maxDistance {float} Maximum driving distance in KM
 * @param {string} accessToken Access token obtained from auth0 to check if user is authorized to access endpoint
 * @returns
 */
export const deleteCalendar = (calendarId, accessToken) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_CALENDAR,
      loading: true,
    });
    try {
      const resp = await calendarService.deleteCalendar(
        calendarId,
        accessToken
      );
      dispatch({
        type: DELETE_CALENDAR_SUCCESS,
        payload: resp.data,
      });
    } catch (err) {
      dispatch({
        type: DELETE_CALENDAR_ERROR,
        error: err,
      });
      throw new Error(`Error ${JSON.stringify(err.toJSON().message)}`);
    }
  };
};
