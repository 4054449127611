export const stepDetails = {
  'locations': {
    showStep: true,
    title: 'model.locationTitle',
    link: '/locations/',
    step: 1,
  },
  'move-type': {
    showStep: true,
    title: 'model.moveTypeTitle',
    link: '/move-type/',
    step: 2,
  },
  'locations-details': {
    showStep: true,
    title: 'model.locationDetailsTitle',
    link: '/locations-details/',
    step: 3,
  },
  'add-ons': {
    showStep: true,
    title: 'model.addOnsTitle',
    link: '/add-ons/',
    step: 4,
  },
  'schedule': {
    showStep: true,
    title: 'model.schedule',
    description: 'model.dateTimeDescription',
    link: '/schedule/',
    step: 5,
  },
  'quote': {
    showStep: false,
    title: 'model.quoteTitle',
    link: '/quote/',
    step: 6,
  },
  'payment': {
    showStep: true,
    title: 'model.paymentTitle',
    link: '/payment/',
    step: 6,
  },
  'guidelines': {
    show: false,
    title: 'model.guidelinesTitle',
    step: 7,
  },
};
