import {
  CLEAR_PARTNER,
  CREATE_PARTNER,
  CREATE_PARTNER_SUCCESS,
  CREATE_PARTNER_ERROR,
  GET_PARTNER,
  GET_PARTNER_SUCCESS,
  GET_PARTNER_ERROR,
  GET_PARTNER_BY_ID,
  GET_PARTNER_BY_ID_SUCCESS,
  GET_PARTNER_BY_ID_ERROR,
  UPDATE_PARTNER,
  UPDATE_PARTNER_SUCCESS,
  UPDATE_PARTNER_ERROR,
  DELETE_PARTNER,
  DELETE_PARTNER_SUCCESS,
  DELETE_PARTNER_ERROR,
} from '../constants/b2cPartner';
import B2CPartnerService from '../../services/B2CPartnerService';

const partnerService = new B2CPartnerService();

/**
 * Reset partner viewedPartner
 */
export const resetB2CPartner = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_PARTNER });
  };
};

/**
 * Gets the list of partners
 * @param {string} accessToken
 */
export const getB2CPartners = (accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_PARTNER,
      });
      const resp = await partnerService.getB2CPartner(accessToken);
      dispatch({
        type: GET_PARTNER_SUCCESS,
        partners: resp,
      });
    } catch (error) {
      dispatch({
        type: GET_PARTNER_ERROR,
        payload: error,
      });
    }
  };
};

/**
 * Gets a partner by ID
 * @param {string} partnerId
 * @param {string} accessToken
 */
export const getB2CPartnerById = (partnerId, accessToken) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_PARTNER_BY_ID,
      });
      const resp = await partnerService.getB2CPartnerById(
        partnerId,
        accessToken
      );
      dispatch({
        type: GET_PARTNER_BY_ID_SUCCESS,
        data: resp,
      });
    } catch (error) {
      dispatch({
        type: GET_PARTNER_BY_ID_ERROR,
        payload: error,
      });
    }
  };
};
/**
 * Creates a partner
 * @param {obj} partnerData The data to create partner
 *                      name {string} the partner name
 *                      description {string} the partner description
 *                      discountCode {string} the partner discountCode
 *                      cities {array} the partner cities
 *                      url {string} the partner url
 *                      logo {string} the partner logo
 * @param {string} accessToken Access token obtained from auth0 to check if user is authorized to access endpoint
 * @returns
 */
export const createB2CPartner = (partnerData, accessToken) => {
  return async (dispatch) => {
    dispatch({
      type: CREATE_PARTNER,
      loading: true,
    });
    try {
      const resp = await partnerService.createB2CPartner(
        partnerData,
        accessToken
      );
      dispatch({
        type: CREATE_PARTNER_SUCCESS,
        payload: resp.data,
      });
    } catch (err) {
      dispatch({
        type: CREATE_PARTNER_ERROR,
        error: err,
      });
    }
  };
};
/**
 * Update a partner
 * @param {obj} partnerData The data to update partner
 *                      id {string} the partner id
 *                      name {string} the partner name
 *                      description {string} the partner description
 *                      discountCode {string} the partner discountCode
 *                      cities {array} the partner cities
 *                      url {string} the partner url
 *                      logo {string} the partner logo
 * @param {string} accessToken Access token obtained from auth0 to check if user is authorized to access endpoint
 * @returns
 */
export const updateB2CPartner = (partnerId, partnerData, accessToken) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_PARTNER,
      loading: true,
    });
    try {
      const resp = await partnerService.updateB2CPartner(
        partnerId,
        partnerData,
        accessToken
      );
      dispatch({
        type: UPDATE_PARTNER_SUCCESS,
        payload: resp.data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_PARTNER_ERROR,
        error: err,
      });
    }
  };
};

/**
 * Update a partner
 * @param {obj} partnerData The data to update partner
 *                      id {string} the partner id
 *                      name {string} the partner name
 *                      description {string} the partner description
 *                      discountCode {string} the partner discountCode
 *                      cities {array} the partner cities
 *                      url {string} the partner url
 *                      logo {string} the partner logo
 * @param {string} accessToken Access token obtained from auth0 to check if user is authorized to access endpoint
 * @returns
 */
export const deleteB2CPartner = (partnerId, accessToken) => {
  return async (dispatch) => {
    dispatch({
      type: DELETE_PARTNER,
      loading: true,
    });
    try {
      const resp = await partnerService.deleteB2CPartner(
        partnerId,
        accessToken
      );
      dispatch({
        type: DELETE_PARTNER_SUCCESS,
        payload: resp.data,
      });
    } catch (err) {
      dispatch({
        type: DELETE_PARTNER_ERROR,
        error: err,
      });
    }
  };
};
